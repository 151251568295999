<template>
  <div id="buy-point">
    <div class="buy-point">
      <card-header ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <div class="buy-point__content">
          <p class="f-w3 current-point__text">現在の所有ポイント</p>
          <p class="f-w6 current-point__number">
            {{ Intl.NumberFormat().format(user.pg_points_balance) }}P
          </p>
          <div class="buy-point__content__wrap">
            <div
              v-for="(pointPackage, index) in pointPackages"
              :key="index"
              class="d-flex justify-content-between align-items-center buy-point__content__div"
            >
              <div class="d-flex justify-content-start align-items-center">
                <img src="@/assets/image/icon_menu_mypage/P.svg" />
                <p class="f-w6">
                  {{ Intl.NumberFormat().format(pointPackage.points) }}P
                </p>
              </div>
              <button class="f-w3" @click="buyPoint(pointPackage)">
                {{ Intl.NumberFormat().format(pointPackage.price) }}円
              </button>
            </div>
          </div>
          <p class="text-center f-w3 mt-3 text-note">
            動作環境及び必要なスペック（Google
            Chrome：バージョン92以降、Safari：バージョン14以降、Microsoft
            Edge：バージョン92以降）
          </p>
        </div>
      </div>
      <card-footer ref="footer" />
    </div>
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
import { mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import service from "@/utils/axios";
import { BUY_POINT_PACKAGE, CHECK_PAYMENT_POINT_PACKAGE } from "@/api/api";

export default {
  name: "BuyPoint",
  components: {
    "card-header": CardHeader,
    "card-footer": CardFooter
  },
  metaInfo() {
    return {
      title: "ポイントを購入する",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: null
    };
  },

  computed: {
    ...mapGetters({
      pointPackages: "pointPackage/point_packages",
      user: "auth/user"
    })
  },

  created() {
    if (this.user.sex == 1) {
      this.headerTitle = {
        text: "ポイントを購入する",
        isShowTitle: true,
        isShowLogo: false,
        backTo: "/my-page"
      };
    } else {
      this.headerTitle = {
        text: "ポイントを購入する",
        isShowTitle: true,
        isShowLogo: false,
        backTo: "/cast/my-page"
      };
    }
  },

  mounted() {
    this.getPointPackages();
    this.checkPointAfterPayment();
  },

  destroyed() {
    if (localStorage.getItem("message_id") !== null) {
      localStorage.removeItem("message_id");
    }
  },

  methods: {
    async getPointPackages() {
      this.$root.$refs.loading.start();
      await this.$store.dispatch("pointPackage/getPointPackages");
      this.$root.$refs.loading.finish();
    },

    async buyPoint(pointPackage) {
      this.$root.$refs.loading.start();

      await service({
        url: BUY_POINT_PACKAGE,
        method: "POST",
        data: pointPackage
      })
        .then(async response => {
          const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_KEY);
          const stripe = await stripePromise;
          localStorage.setItem("stripe_checkout_id", response.data.id);
          await stripe.redirectToCheckout({
            sessionId: response.data.id
          });
        })
        .catch(() => {
          this.$toast("決済できませんでした。", "通知", "danger");
        });

      this.$root.$refs.loading.finish();
    },

    async checkPointAfterPayment() {
      let checkoutStatusParam = this.$route.query.checkout_status;
      let stripeCheckoutId = localStorage.getItem("stripe_checkout_id");

      if (checkoutStatusParam !== undefined && stripeCheckoutId !== null) {
        if (checkoutStatusParam !== "success") {
          localStorage.removeItem("stripe_checkout_id");
          return;
        }

        await service({
          url: CHECK_PAYMENT_POINT_PACKAGE + stripeCheckoutId,
          method: "POST"
        })
          .then(async response => {
            await this.$root.$refs.loading.start();
            await this.$store.dispatch("auth/getInfoUser");
            let point = Intl.NumberFormat().format(response.data.points);
            await this.$toast(`${point}Pを購入しました。`, "通知", "success");
            if (localStorage.getItem("message_id") !== null) {
              this.$router.push({
                name: "MessageDetail",
                params: { id: localStorage.getItem("message_id") }
              });
            }
            await this.$root.$refs.loading.finish();
          })
          .catch(async () => {
            await this.$toast("決済できませんでした。", "通知", "danger");
          });

        localStorage.removeItem("stripe_checkout_id");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
@import "@/assets/scss/men/buyPoint.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 60px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}
.text-note {
  font-size: 14px !important;
}

@media screen and (min-width: 1200px) {
  .text-note {
    font-size: 16px !important;
  }
  // .content-scroll {
  //   // height: calc(100vh - 152px);
  // }
}
</style>
